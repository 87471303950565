export enum EventName {
  ISSUE_CARD_SELECTED_BANK = "@issue-card:selected-bank",
  CARDS_LIST_ACTIONS_SHOWED = "@cards-list-actions:showed",
}

export enum EventTarget {
  PRODUCT_TOUR = "product-tour",
}

export enum StepAlias {
  MOCK_TRANSACTIONS_LIST = "mock-transactions-list",
  MOCK_CASH_FLOW_LIST = "mock-cash-flow-list",
  MOCK_CARDS_LIST = "mock-cards-list",
  MOCK_CARDS_ACTIONS = "mock-cards-actions",
  MOCK_CARDS_SET_LIMIT = "mock-cards-set-limit",
  MOCK_TEAM_LIST = "mock-team-list",
  MOCK_TEAM_ACTIONS = "mock-team-actions",
  WELCOME_SCREEN = "welcome-screen",
  PAYMENT_METHOD_USDT_TRANSFER = "payment-method-usdt-transfer",
}
