import { Card, CardStatus, CardLimitType } from "@/types/card";
import { Currency } from "@/types/currency";

const mockCards: Card[] = [
  {
    id: 1,
    cardNumber: "5***********6761",
    cardExpiryMonth: 11,
    cardExpiryYear: 26,
    issued_at: "2023-11-29T21:56:01.000Z",
    status: CardStatus.ACTIVE,
    currency: Currency.EUR,
    limitType: CardLimitType.TOTAL,
    spendAmount: 0,
    refundAmount: 0,
    limitAmount: 53,
    balanceAmount: 53,
    dailySpendAmount: 0,
    dailyLimitAmount: 1,
    autoRefillEnabled: false,
    bankId: 0,
    note: "Example",
    owner: {
      firstName: "Example",
      lastName: "Example",
      email: "example@example.ru",
    },
    prepaid: true,
    overdraftAmount: 0,
  },
  {
    id: 1,
    cardNumber: "5***********8952",
    cardExpiryMonth: 8,
    cardExpiryYear: 26,
    issued_at: "2023-08-08T12:50:33.000Z",
    status: CardStatus.ACTIVE,
    currency: Currency.EUR,
    limitType: CardLimitType.TOTAL,
    spendAmount: 0,
    refundAmount: 0,
    limitAmount: 1,
    balanceAmount: 0,
    dailySpendAmount: 0,
    dailyLimitAmount: 1,
    autoRefillEnabled: false,
    cardGroup: {
      id: 0,
      name: "Example",
      owner: "example@example.ru",
    },
    cardGroupName: "Example",
    bankId: 0,
    note: "Example",
    owner: {
      firstName: "Example",
      lastName: "Example",
      email: "example@example.ru",
    },
    prepaid: false,
    overdraftAmount: 0,
  },
];

export default mockCards;
