import { Component, Vue, Prop } from "vue-property-decorator";

import {
  getSymbolCurrency,
  formatMoney,
  formatDate,
  hashStringToColor,
  getFullName,
  copyTextClipboard,
  isMaskedValue,
  numberToFixed,
} from "@helpers";
import { Card, CardOwner, CardStatus, CardType } from "@/types/card";
import { Currency } from "@/types/currency";
import {
  formatCardExpire,
  formatCardNumber,
  getCardIconSrc,
  getCardPaymentSystem,
} from "@/lib/creaditCard";
import {
  profileModule,
  teamModule,
  cardModule,
  userModule,
  walletModule,
} from "@store/namespaces";
import { TeamActions } from "@store/modules/team/types";
import { ProfileGetters } from "@store/modules/profile/types";

import { CardActions as ICardActions } from "@store/modules/card/types";
import { UserGetters } from "@store/modules/user/types";
import { WalletGetters } from "@store/modules/wallet/types";
import { HtmlElementClassName } from "@/types/element";

@Component({
  inheritAttrs: false,
})
export default class CardMixin extends Vue {
  @teamModule.Action("fetchTeamMembers")
  private readonly fetchTeamMembersAction!: TeamActions["fetchTeamMembers"];
  @profileModule.Getter("profileEmail")
  private readonly profileEmailGetter!: ProfileGetters["profileEmail"];
  @cardModule.Action("fetchCardNumber")
  private readonly fetchCardNumberAction!: ICardActions["fetchCardNumber"];
  @cardModule.Action("fetchCardCvv")
  private readonly fetchCardCvvAction!: ICardActions["fetchCardCvv"];
  @profileModule.Getter("profileLanguage")
  private readonly profileLanguageGetter!: ProfileGetters["profileLanguage"];
  @profileModule.Getter("canViewTeamCardData")
  private readonly canViewTeamCardDataGetter!: ProfileGetters["canViewTeamCardData"];
  @profileModule.Getter("userHasRole")
  private readonly userHasRoleGetter!: ProfileGetters["userHasRole"];
  @userModule.Getter("userIsGhost")
  private readonly userIsGhostGetter!: UserGetters["userIsGhost"];
  @walletModule.Getter("walletBalance")
  private readonly walletBalanceGetter!: WalletGetters["walletBalance"];

  @Prop({ type: String }) private readonly id?: string;
  @Prop({ type: String, required: true })
  private readonly cardType!: CardType;
  @Prop({ type: Array, required: true })
  private readonly unfreezingCardIds!: number;

  private get htmlElementClassName() {
    return {
      changeCardBalanceButton: HtmlElementClassName.changeCardBalanceButton,
    };
  }

  private get isShowedMainIntro() {
    return this.$productTour.activeIntro.type === "product-tour";
  }

  private get isShowAutoRefillMenu() {
    return this.cardType === CardType.PREPAID;
  }

  private get langCode() {
    return this.profileLanguageGetter(this.$vuetify.lang.current);
  }

  private getCardNote({ note, id }: Card) {
    return note || this.$vuetify.lang.t("$vuetify.dashboard.card_n", id);
  }

  private getWalletBalanceByCurrency(currency: Currency) {
    return this.walletBalanceGetter[currency];
  }

  private checkShowCardSetLimitMenu({ status }: Card) {
    return this.cardType === CardType.POSTPAID && !this.isSuspendCard(status);
  }

  private checkShowChangeCardBalanceMenu({ status }: Card) {
    return this.cardType === CardType.PREPAID && !this.isSuspendCard(status);
  }

  private checkShowUnfreezeCardButton({ status }: Card) {
    return this.isSuspendCard(status);
  }

  private getStatusInfo({ status }: Card) {
    if (this.isActiveCard(status)) {
      return {
        text: this.$vuetify.lang.t("$vuetify.dashboard.cards.status.active"),
        color: "#45df82",
        icon: "$check",
      };
    }

    if (this.isFreezeCard(status)) {
      return {
        text: this.$vuetify.lang.t("$vuetify.dashboard.cards.status.freeze"),
        color: "#243143",
        icon: "$pauseL",
      };
    }

    if (this.isSuspendCard(status)) {
      return {
        text: this.$vuetify.lang.t("$vuetify.dashboard.cards.status.suspend"),
        color: "#acb8c9",
        icon: "$severeCold",
      };
    }

    return {
      text: this.$vuetify.lang.t("$vuetify.dashboard.cards.status.closed"),
      color: "#ff515f",
      icon: "$close",
    };
  }

  private currentUserIsOwnerCard(email: string) {
    return email === this.profileEmailGetter;
  }

  private canRefillCard({ status, owner: { email } }: Card) {
    if (this.isShowedMainIntro) {
      return true;
    }

    return this.isActiveCard(status) && this.currentUserIsOwnerCard(email);
  }

  private canSetLimitCard(item: Card) {
    if (this.isShowedMainIntro) {
      return true;
    }

    return (
      this.isActiveCard(item.status) &&
      this.currentUserIsOwnerCard(item.owner.email)
    );
  }

  private canFetchCardNumberAndCvv({ owner: { email } }: Card) {
    if (this.userIsGhostGetter) {
      return false;
    }

    return this.currentUserIsOwnerCard(email) || this.canViewTeamCardDataGetter;
  }

  protected canChangeGroup({ owner: { email } }: Card): boolean {
    return this.currentUserIsOwnerCard(email) && !this.userIsGhostGetter;
  }

  protected canEditNote({ owner: { email } }: Card): boolean {
    return this.currentUserIsOwnerCard(email) && !this.userIsGhostGetter;
  }

  private onCopyCardNumber(value: string) {
    this.copyText(value);
  }

  protected onChangeGroupCard(groupId: number, item: Card): void {
    if (!groupId) return;
    this.$emit("change:group", groupId, item);
  }

  protected onChangeNoteCard(val: string, item: Card): void {
    this.$emit("change:note", val.trim(), item);
  }

  private onCloseCard(id: number) {
    this.$emit("close:card", id);
  }

  private onFreezeCard(id: number) {
    this.$emit("freeze:card", id);
  }

  private onUnfreezeCard(id: number) {
    this.$emit("unfreeze:card", id);
  }

  private onSuccessSetLimit() {
    this.$emit("success:set-limit");
  }

  private onSuccessRefill() {
    this.$emit("success:refill");
  }

  private onSuccessAutoRefill() {
    this.$emit("success:auto-refill");
  }

  private onChangeCardBalance() {
    this.$emit("success:change-card-balance");
  }

  private isActiveCard(status: CardStatus) {
    return status === CardStatus.ACTIVE;
  }

  private isFreezeCard(status: CardStatus) {
    return status === CardStatus.FREEZE;
  }

  private isSuspendCard(status: CardStatus) {
    return status === CardStatus.SUSPEND;
  }

  private isMaskedCardNumber(value: string) {
    return isMaskedValue(value, "cardNumber");
  }

  private isMaskedCardCvv(value?: string) {
    return !value?.length;
  }

  private formatLimitAmount(value: number | undefined, currency: Currency) {
    if (typeof value === "number") {
      return this.formatAmount(value, currency);
    }

    return "∞";
  }

  private formatAmount(value: number, currency: Currency) {
    return formatMoney({
      value,
      currency,
      showSymbol: false,
    });
  }

  private formatCardNumber(val: string) {
    return formatCardNumber(val);
  }

  private getCardIconSrc(cardNumber: string) {
    return getCardIconSrc(getCardPaymentSystem(cardNumber));
  }

  private getSymbolCurrency(currency: Currency) {
    return getSymbolCurrency(currency);
  }

  private hashStringToColor(val: string) {
    return hashStringToColor(val);
  }

  private getOwnerFullName({ firstName, lastName, email }: CardOwner) {
    return getFullName({
      firstName,
      lastName,
      fallback: email,
    });
  }

  private formatCardExpire(month: number, year: number) {
    return formatCardExpire(month, year);
  }

  private formatDate(val: string) {
    return formatDate(val);
  }

  private copyText(str: string) {
    copyTextClipboard(str);
    this.$notify({
      type: "info",
      title: this.$vuetify.lang.t("$vuetify.info.copied"),
    });
  }

  private getProgressbarValue(current: number, total?: number) {
    total = Math.max(current, total || 0);

    return Math.min(numberToFixed((current * 100) / total, 2), 100);
  }
}
